import { EspaceData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Admin/Form';
import params from '../../../../params/boutique/index.json';
import TemplateAdmin from '../../../../templates/admin';
import requireUser from '../../../../utils/requireUser';

const PageAdminBoutiquesUpdate: FC<PageProps> = props => {
  const {
    location,
    params: { id },
  } = props;

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix={location.pathname}
          model={new EspaceData({ params })}
          name="espace"
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminBoutiquesUpdate, 'admin');
